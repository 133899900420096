import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { ASK_QUESTION_URL } from "../../constants";

class AskAQuestion extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showQuestionForm: false,
            showSuccess: false,
            showFail: false,
            validated: false
        }
    }

    handleSubmit = (event) => {
        const form = event.currentTarget;
        
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            this.setState({validated: true});
            return;
        }

        const email = event.target.elements.validateEmail.value;
        const fullName = event.target.elements.validateFullName.value;
        const phone = event.target.elements.validatePhone.value;
        const message = event.target.elements.validateMessage.value;

        axios.post(ASK_QUESTION_URL, {
            email: email,
            fullName: fullName,
            phone: phone,
            message: message
          })
            .then((response) => {
                if (response.data.success === true)
                    this.setState({showQuestionForm: false, showSuccess: true});
                else
                    this.setState({showQuestionForm: false, showFail: true})
            })
            .catch(error => { 
              console.log(error);
              this.setState({showQuestionForm: false, showFail: true});
            })
            .finally(() => {
            this.setState({validated: true});
        });
    };

    render() {
        return (
            <>
                <span className="trigger-modal">
                    <Button className="btn btn-ask-quiz" onClick={() => this.setState({showQuestionForm: true})}>Ask a Question</Button>
                </span>

                {/* Success Modal start*/}
                <Modal
                    show={this.state.showSuccess}
                    className="modal-cvri-success"
                    onHide={() => this.setState({showSuccess: false})}
                    dialogClassName="modal-90w modal-cvri-success"
                    aria-labelledby="modal-cvri-success"
                >
                    <Modal.Header closeButton ></Modal.Header>
                    <Modal.Body>
                        <div className="thank-you-pop">
                            <img src={process.env.PUBLIC_URL + '/images/Green-Round-Tick.png'} alt="CVRI Dublin" />
                            <h1>Thank You!</h1>
                            <p>Your submission is received and we will contact you soon</p>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* Success Modal end*/}

                
                {/* Fail Modal start*/}
                <Modal
                    show={this.state.showFail}
                    className="modal-cvri-fail"
                    onHide={() => this.setState({showFail: false})}
                    dialogClassName="modal-90w modal-cvri-fail"
                    aria-labelledby="modal-cvri-fail"
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="thank-you-pop">
                            <img src={process.env.PUBLIC_URL + '/images/red-x.png'} alt="CVRI Dublin" />
                            <h1>Ooops!!</h1>
                            <p>Something went wrong. Please contact us at <a href="mailto: cvri@materprivate.ie">cvri@materprivate.ie</a></p>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* Fail Modal end*/}


                <Modal
                    show={this.state.showQuestionForm}
                    className="modal-cvri"
                    onHide={() => this.setState({showQuestionForm: false})}
                    dialogClassName="modal-90w contact-form-modal"
                    aria-labelledby="contact-form-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Send us your enquiry
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                            <div className="form-wrapper">
                                <Row className="mb-3">
                                    <Form.Group as={Col} className="col-sm" controlId="validateFullName">
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Full Name"
                                            maxLength="300"
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">Please enter your full name.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} className="col-sm" controlId="validatePhone">
                                        <Form.Control
                                            type="tel"
                                            placeholder="Phone"
                                            pattern="[0-9\+]{6,20}"
                                            maxLength="20"
                                        />
                                        {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                        <Form.Control.Feedback type="invalid">Please enter a valid phone number.</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} className="col-sm" controlId="validateEmail">
                                        <Form.Control
                                            required
                                            type="email"
                                            placeholder="Email"
                                            pattern="\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+"
                                            maxLength="70"
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} className="col-sm" controlId="validateMessage">
                                        <Form.Control
                                            as="textarea"
                                            required
                                            placeholder="How Can We Help?"
                                            style={{ height: '300px' }}
                                            maxLength="1000"
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">Please enter a message.</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </div>
                            <Button type="submit" variant="primary" className="ask-question-button">Submit</Button> 
                        </Form>                
                    </Modal.Body>
                </Modal>
            </>
        )

    }
}

export default AskAQuestion;