import React from "react";
import * as constants from '../../constants';
import Title from '../page_parts/Title';
import Breadcrumb from '../page_parts/Breadcrumb';
import axios from "axios";
import {Link} from "react-router-dom";
import {addEndingSlashToSlug, getPageBySlug, extractExcerptContent} from '../../utils/general';
import CVRIPagination from "../page_parts/CVRIPagination";
import SideBar from "../page_parts/SideBar";

class ArticlesPages extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            allPages: this.props.allPages,
            page: null, //rather than querying the db to set this, we set it from the props.allPages as we don't need content of the page.
            articles: [],
            total: 0,
        }

        this.pageLimit = 10;
        this.currentPgNo = 1;
    }

    render() {

        if (!this.state.page) {
            return null;
        }

        return (
            <>
                <Breadcrumb allPages={this.state.allPages} />
                <Title currentPage={this.state.page} />
                {this.__getContent()}
            </>

        )
    }


    componentDidMount() {
        this._isMounted = true;
        this.__loadPageContent(this.pageLimit);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    __getContent = () => {
        
        let content = [];
        for (const [index, article] of this.state.articles.entries()) {

            const artContent = (article.excerpt && article.excerpt.trim().length > 0) ? article.excerpt.trim(): extractExcerptContent(article.description, 500) ;
            const artSlug = addEndingSlashToSlug(article.slug);

            let articleImage = '';
            if (article && article.image && article.image.hasOwnProperty('url') && article.image.url.length > 0) {
                articleImage = <img alt={article.title} src={constants.CMS_URL + article.image.url} />
            }

            content.push(
                (
                <div className="results-info" key={index}>
                    <h3>
                        <Link to={artSlug}>{article.title}</Link>
                    </h3>
                    
                    <div className="article-content">
                        
                        <div className="article-image">
                            <Link to={artSlug}>{articleImage}</Link>
                        </div>
                        <p>
                            {artContent + " "} 
                            <Link className="read-more" to={artSlug}>Read more</Link>
                        </p>
                    </div>
                    <div className="clear"></div>
                </div>
                )
            )
        }

        return (
           <main id="main-content" className="inner-main spotlight-landing-layout template-no-gaps col-sm articlespage">
                <section id="spotlight-area" className="container">
                    <div className="cvri-sidebar-content-wrapper">
                        <div>
                            <SideBar menuItems={this.props.menuItems} />
                        </div>
                        <div>
                            {content}
                            <CVRIPagination currentPgNo={this.currentPgNo} type="news articles" total={this.state.total} pageLimit={this.pageLimit} loadPageContent={this.__loadPageContent} />
                        </div>
                    </div>
            </section>
        </main>
        )
    }

    __loadPageContent = (limit, page=1) => {

        const start = (page === 1)?0: page * limit - limit;
        let queryDataTypes = [this.__getArticles(start, limit)];

        //query the total items only if we have no total
        if (!this.state.total) {
            queryDataTypes.push(this.__getTotalArticles());
        }

        axios.all(queryDataTypes)
        .then((results) => {
            if (this._isMounted) {

                let toState = {
                    articles: results[0].data,
                }

                //add total only if it exists
                if (results.length > 1) {
                    toState.total = results[1].data
                }

                //if no current page, add it
                if (!this.state.page) {
                    toState.page = getPageBySlug(window.location.pathname, this.state.allPages)
                }

                this.currentPgNo = page;

                this.setState(toState);
            }
        })
        .catch((error) => {
            console.log('Error loading articles: ', error);
        });

    }

    __getArticles(pgStart, limit) {
        return axios.get(encodeURI(`${constants.ARTICLES_API_URL}?_sort=published_at:DESC&_start=${pgStart}&_limit=${limit}`));
    }

    __getTotalArticles() {
        return axios.get(constants.ARTICLES_API_URL + '/count');
    }

}

export default ArticlesPages;


