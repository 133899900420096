import React from "react";
import { getMenuItem, addEndingSlashToSlug } from "../../utils/general";
import {Link} from "react-router-dom";

class SideBar extends React.Component {

    constructor(props) {
        super(props)
        
        this.state = {
            menuItems: this.props.menuItems,
            listItems: [],
            title: null,
            linkedTitle: null
        }
    }



    render() {

        if (this.state.listItems.length === 0) {
            return null;
        }
        
        return (
            <>
                <aside className="right-side-nav cvri-right-sidebar">
                    <h3>{this.state.linkedTitle}</h3>
                    <ul className="no-bullet">
                        {this.state.listItems}
                    </ul>
                </aside>
            </>
        )
    }

    componentDidMount() {
        let listItems = [];

        if (!this.state.menuItems) {
            console.log('No menuItems passed on to the sidebar');
            return null;
        }

        let sideBarItems =  getMenuItem(this.state.menuItems, window.location.pathname, 2, ['sub_pages']);

        if (!sideBarItems.sub_pages) {
            sideBarItems =  getMenuItem(this.state.menuItems, window.location.pathname, 3, ['sub_pages']);
        }

        if (!sideBarItems.sub_pages) {
            return null;
        }

        
        for (let i = 0; i<sideBarItems['sub_pages'].length; i++) {
            let secondLevelList = '';
            let menuItem = sideBarItems['sub_pages'][i];
            let activeClass = (addEndingSlashToSlug(menuItem.full_slug) === addEndingSlashToSlug(window.location.pathname)) ? 'active-item' : '';
            
            // do we have another level of sub pages?
            if (menuItem.sub_pages && menuItem.sub_pages.length > 0) {
                let secondLevelListItems = [];
                for (let j = 0; j<menuItem['sub_pages'].length; j++) {
                    let menuItem2 = menuItem['sub_pages'][j];
                    secondLevelListItems.push(<li key={j + 100}><Link to={addEndingSlashToSlug(menuItem2.full_slug)}>{menuItem2.title}</Link></li>)
                }
                secondLevelList = <ul className="no-bullet">{secondLevelListItems}</ ul>
            }

            listItems.push(<li className={activeClass} key={i}><Link to={addEndingSlashToSlug(menuItem.full_slug)}>{menuItem.title}</Link> {secondLevelList}</li>)
        }

        let linkedTitle = <Link to={addEndingSlashToSlug(sideBarItems.full_slug)}>{sideBarItems.title}</Link>;

        this.setState({listItems: listItems, title: sideBarItems.title, linkedTitle: linkedTitle});
        
    }
}

export default SideBar;