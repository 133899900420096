import React from "react";
import {Link} from "react-router-dom";
import {addEndingSlashToSlug, clickGoToFirstPage, isDisabledPage} from "../../utils/general";


class Breadcrumb extends React.Component {

    render() {

        //if empty don't show the bread crumb section
        const breadCrumbs = this.__buildCrumbs();

        if (breadCrumbs.length === 0) {
            return null;
        }

        return (
            <section className="bg-light breadcrumb-wrapper">
                <div className="container">
                <nav aria-label="breadcrumb" className="wp-breadcrumbs">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item" key="0" ><Link to="/">Home</Link> </li>
                        {
                            breadCrumbs.map((page, index) => {
                                const slug = (isDisabledPage(page.slug)) ? '#': page.slug;
                                return (
                                    <li className="breadcrumb-item" key={index} >
                                        <Link to={`${addEndingSlashToSlug(slug)}`}>{page.title}</Link>
                                    </li>
                                )
                            })
                        }
                    </ol>
                </nav>
                </div>
            </section>
        )
    }

    __buildCrumbs = () => {
        let slugs = this.__getPathnameSlugs();
        let pages = [];

        for (const slug of slugs) {
            let myPg = JSON.parse(JSON.stringify(this.__getPageBySlug(slug)));
            pages.push(myPg);
        }

        let topSlug = '';
        for (let pg of pages.filter(Boolean)) {
            topSlug = topSlug + '/' + pg.slug;
            pg.slug = topSlug;
        }

        return pages;
    }

    __getPathnameSlugs = () => {
        let pathname = window.location.pathname;
        let pathParts = pathname.split('/').filter(Boolean);
        return pathParts;
    }

    __getPageBySlug = (slug) => {
        const allPages = JSON.parse(JSON.stringify(this.props.allPages));


        //add articles to all pages so we can construct the breadcrumbs. 
        //they must have title and slug
        if (this.props.extra) {
            let extra = JSON.parse(JSON.stringify(this.props.extra));
            if (extra.hasOwnProperty('slug') && extra.hasOwnProperty('title')) {
                allPages.push(extra);
            }
            
        }

        let currentPage = null;
        for (const page of allPages) {
            if (page.slug === slug) {
                currentPage = page;
            }
        }

        return currentPage;
    }

    componentDidMount() {
        //go to first page when we click a menu item
        clickGoToFirstPage(".breadcrumb-item a");
    }
}

export default Breadcrumb;