import React from "react";
import {Link} from "react-router-dom";
import ArticleCarousel from "../page_parts/ArticleCarousel";
import {addEndingSlashToSlug, getPageBySlug} from '../../utils/general';
import * as constants from "../../constants/index";
import Axios from "axios";
import SanitizedContent from "../page_parts/SanitizedContent";


class HomePage extends React.Component {

    constructor(props) {
      super(props)

      this.state = {
          page: null
      }
    }

      
    render() {

      const homePage = this.state.page;
      
      if (!homePage) {
          console.log('no page content yet');
          return null;
      }

      let homeImage = process.env.PUBLIC_URL + "images/banners/dublin_liffey.jpg";

      if (homePage.featured_image) {
        homeImage = constants.CMS_URL + homePage.featured_image.url;
      }

      const pages = this.props.allPages;

      const outcomesResearch = getPageBySlug(constants.RESEARCH_OUTCOMES_RESEARCH, pages);
      outcomesResearch.slug = addEndingSlashToSlug(outcomesResearch.slug);

      const clinicalTrials = getPageBySlug(constants.RESEARCH_CLINICAL_TRIALS, pages);
      clinicalTrials.slug = addEndingSlashToSlug(clinicalTrials.slug);

      const coreLab = getPageBySlug(constants.RESEARCH_CORE_LAB, pages);
      coreLab.slug = addEndingSlashToSlug(coreLab.slug);

      const systematicReview = getPageBySlug(constants.RESEARCH_SYSTEMATIC_REVIEW, pages);
      systematicReview.slug = addEndingSlashToSlug(systematicReview.slug);
   
      const electrophisiologyResearch = getPageBySlug(constants.ELECTROPHYSIOLOGY_RESEARCH, pages);
      electrophisiologyResearch.slug = addEndingSlashToSlug(electrophisiologyResearch.slug);


      return (
            <main id="main-content" className="home-layout">
                <div className="video-banner">
                  <div className="container content-box-wrapper">
                    <div className="content-box">
                      <SanitizedContent content={homePage.description} />
                      <Link className="btn btn-primary"  to="about/who-we-are/">Find out more</Link>
                    </div>
                  </div>
                  <div className="video-container">
                    <div className="d-none d-sm-block">
                      <div className="centered-image">
                        <img src={homeImage} className="poster" alt="Cardiovascular Research Institute Dublin" />
                      </div>
                    </div>
                    <div className="d-block d-sm-none centered-image">
                      <img className="poster" src={homeImage} alt="Cardiovascular Research Institute Dublin" />
                    </div>
                  </div>
                </div>
                <section className="container spotlight-area-wrapper cvri-home-research">
                  <div className="no-gaps">
                    <div className=" wp-image-title-spotlight cvri-research-section">
                    <Link to={constants.RESEARCH_PARENT_SLUG + '/' + outcomesResearch.slug} className="spotlight image-overlay fixed-lines spotlight-minimal">
                        <div className="spot-content ">
                          <span className="btn btn-secondary">{outcomesResearch.title}</span>
                          <p className="d-none" />
                        </div>
                      </Link>
                    </div>
                    <div className=" wp-image-title-spotlight cvri-research-section">
                      <Link to={constants.RESEARCH_PARENT_SLUG + '/' + clinicalTrials.slug} className="spotlight image-overlay fixed-lines">
                        <div className="spot-content ">
                            <span className="btn btn-secondary">{clinicalTrials.title}</span>
                        </div>
                      </Link>
                    </div>
                    <div className=" wp-image-title-spotlight cvri-research-section">
                      <Link to={constants.RESEARCH_PARENT_SLUG + '/' + coreLab.slug} className="spotlight image-overlay fixed-lines">
                        <div className="spot-content ">
                            <span className="btn btn-secondary">{coreLab.title}</span>
                        </div>
                      </Link>
                    </div>
                    <div className=" wp-image-title-spotlight cvri-research-section">
                        <Link to={constants.RESEARCH_PARENT_SLUG + '/' + systematicReview.slug} className="spotlight image-overlay fixed-lines" >
                          <div className="spot-content ">
                              <span className="btn btn-secondary">{systematicReview.title}</span>
                          </div>
                        </Link>
                    </div>
                    <div className=" wp-image-title-spotlight cvri-research-section">
                        <Link to={constants.ELECTROPHYSIOLOGY_RESEARCH + '/' + electrophisiologyResearch.slug} className="spotlight image-overlay fixed-lines" >
                          <div className="spot-content ">
                              <span className="btn btn-secondary">{electrophisiologyResearch.title}</span>
                          </div>
                        </Link>
                    </div>
                  </div>
                </section>
                <section className="cards-wrapper wp-card-content-slider">
                  <div className="container">
                    <h2>Latest News</h2>
                    <div className="row zero-xs-margin">
                      <ArticleCarousel />
                    </div>
                  </div>
                </section>
            </main>
        )
    }

    componentDidMount() {
        this._isMounted = true;
        document.title = 'Home - CVRI';
        this.__loadPageContent();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    __loadPageContent = () => {

      const currentSlug = '/';

      Axios.get(constants.PAGES_API_URL + '?slug='+currentSlug)
          .then(
              (result) => {
                  let pageData = result.data;

                  //if data is an array work with that. 
                  if (Array.isArray(result.data) && result.data.length > 0) {
                      pageData = result.data[0];
                  }

                  if (this._isMounted) {
                      this.setState({
                          page: pageData
                      });
                  }
                  
              }
          )
          .catch((error) => {
              // handle error
              console.log('Error fetching the page content: ', error);
          }
      );
  }

}

export default HomePage;