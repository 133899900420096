import React from "react";
import SanitizedHTML from "react-sanitized-html";
import * as constants from '../../constants';


class SanitizedContent extends React.Component {

    render() {
        const pageContent = this.props.content;

        if (!pageContent || pageContent === null) {
            return null;
        }

        let pageContentWithPaths = this.__addFullPathToImages(pageContent);
        pageContentWithPaths = this.__addTargetBlankToLinks(pageContentWithPaths);

        return (
            <SanitizedHTML
                html={pageContentWithPaths} 
                allowedAttributes={{ 'a': ['href', 'style', 'target'], 'img': ['src', 'style', 'width', 'height', 'class'],
                'span': ['class', 'style'], 'p': ['style', 'class'], 'h2': ['style', 'class'],'h1': ['style', 'class'], 'figure': ['style', 'class'], 'oembed': ['url'], 'iframe':['allowfullscreen', 'src', 'frameborder', 'allow'], 'div': ['class']}}
                nonTextTags={[ 'style', 'script', 'textarea', 'option', 'noscript' ]}
                allowedTags={['a', 'img', 'b', 'i', 'em', 'strong', 'u', 'p', "h1", "h2", "h3", "h4",'span', 'hr',
                "h5", "h6", 'ul', 'ol', 'li', 'table', 'tr', 'td', 'th', 'figure', 'oembed', 'iframe', 'div']}
            />
        )
    }

    /**
     * adds the CMS full path for the uploaded images. 
     * @param {String} pageContent 
     */
    __addFullPathToImages = pageContent => {

        if (!pageContent|| pageContent.indexOf('/uploads/') === -1) {
            return pageContent;
        }

        const uploadsDirString = '="/uploads/';
        const fullUploadsDirString = `="${constants.CMS_URL}/uploads/`;
        return pageContent.split(uploadsDirString).join(fullUploadsDirString);
    }

    __addTargetBlankToLinks = pageContent => {
        const linkWithoutTarget = '<a href="http';
        if (!pageContent|| pageContent.indexOf(linkWithoutTarget) === -1) {
            return pageContent;
        }
        const linkWithTarget = `<a target="_blank" href="http`;
        return pageContent.split(linkWithoutTarget).join(linkWithTarget);
    }
}

export default SanitizedContent;