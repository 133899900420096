import React from "react";
import Navigation from "./Navigation";
import {Link} from "react-router-dom";
import TopLinks from "./TopLinks";
import { withRouter} from "react-router-dom";


class Header extends React.Component {

    render() {

        let phoneNo = this.__extractPhone();
        let cleanPhoneNo = phoneNo.replace(/ /g, "");
        

        console.log('rendering header');
        return (
            <header id="nav_drop_down" data-expand-on-hover="yes" data-expand-on-touch="yes" className="expand-on-touch">
                <div id="nav_drop_down_box">
                    <div className="container">
                        <div className="cvri-sub-container">
                            <div className="row cvri-top">
                                <div className="logo-column">
                                    <Link to="/" className="logo-link">
                                        <img src={process.env.PUBLIC_URL + '/images/cvri_main_logo.png'} className="cvri-top-logo" alt="Cardiovascular Research Institute" />
                                    </Link>
                                    <a href="https://www.materprivate.ie/" target="__blank" className="logo-link mpn-cvri-logo">
                                        <img className="mpn-cvri-logo-img" src={process.env.PUBLIC_URL + '/images/logo-network.svg'} alt="Mater Private Network" />
                                    </a>
                                    <a href="https://www.rcsi.com/dublin" target="__blank" className="logo-link mpn-rcsi-logo">
                                        <img className="mpn-logo-img" src={process.env.PUBLIC_URL + '/images/inc-logo.svg'} alt="University of Medicine and Health Services" />
                                    </a>
                                </div>
                                <TopLinks type="desktop" cleanPhoneNo={cleanPhoneNo} phoneNo={phoneNo} pages={this.props.pages} />
                            </div>
                            <div className="row cvri-top-menu">
                                <div className="toggle" role="button" aria-label="Open Menu" aria-controls="hc-nav-1" aria-expanded="false">
                                    <span></span>
                                </div>
                                <Navigation menuItems={this.props.menuItems} data={this.props.data} cleanPhoneNo={cleanPhoneNo} phoneNo={phoneNo} contact={this.props.contact} />
                            </div>
                        </div>
                    </div>
                    <div className="clear"></div>
                </div>
            </header>
        )
    }


    __extractPhone = () => {

        const phoneItem = this.props.contact.body.map((item, index) => {
            return (item.type === 'telephone')?item: null;
        }).filter(Boolean);

        let dataPhone = phoneItem[0].value;

        return dataPhone;
    }

}

export default withRouter(Header);