import React from "react";
import Title from '../page_parts/Title';
import Breadcrumb from '../page_parts/Breadcrumb';
import axios from "axios";
import * as constants from '../../constants';
import { getValidSlug, getPageBySlug, getIrishTime } from '../../utils/general';
import CVRIPagination from "../page_parts/CVRIPagination";
import SideBar from "../page_parts/SideBar";
import EventsSearchBox from "../page_parts/EventsSearchBox";
import fuzzysort from 'fuzzysort'
import { sliceIntoChunks } from "../../utils/general";



class CVRIForumEventPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            allPages: this.props.allPages,
            page: null,
            eventType: getValidSlug(window.location.pathname),
            events: [],
            total: 0,
            autocompleteEvents: [],
            _id: this.props._id,
            searchResults: [],
        }

        this.pageLimit = 10;
        this.currentPgNo = 1;
        this.searchMode = false;
    }

    __setSearchMode(val) {
        this.searchMode = val;
        if (this.searchMode === false) {
            this.setState({
                total: 0
            })
        }
    }

    render() {

        if (!this.state.page) {
            console.log('no page content yet');
            return null;
        }

        let currentPage = JSON.parse(JSON.stringify(this.state.page));
        const eventType = this.state.eventType;
        const eventTitle = (eventType.indexOf('future') !== -1) ? 'Future Events' : 'Past Events';

        //forcing the current breadcrumb item
        const breadcrumbExtra = {
            title: eventTitle,
            slug: eventType
        }
        currentPage.title = currentPage.title + ' - ' + eventTitle;

        return (
            <>
                <Breadcrumb allPages={this.state.allPages} extra={breadcrumbExtra} />
                <Title currentPage={currentPage} />
                <main id="main-content" className="inner-main spotlight-landing-layout template-no-gaps articlespage">
                    <section id="spotlight-area" className="container">
                        <div className="cvri-sidebar-content-wrapper">
                            <div>
                                <SideBar menuItems={this.props.menuItems} />
                            </div>
                            <div className="events-search-container">
                                <EventsSearchBox filterEventsBySearchValue={this.filterEventsBySearchValue} searchFilterClear={this.searchFilterClear} />
                                {this.__getViewContent(this.state.events.entries())}
                                <CVRIPagination currentPgNo={this.currentPgNo} type="events" total={this.state.total} pageLimit={this.pageLimit} loadPageContent={this.__loadPageContent} />
                            </div>
                        </div>
                    </section>
                </main>
            </>

        )

    }

    componentDidMount() {
        this._isMounted = true;
        this.__loadPageContent(this.pageLimit);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    __getViewContent = (entries) => {

        let content = [];
        for (const [index, event] of entries) {

            let eventTitle = <h3>{event.title}</h3>;
            let goToEvent = '';

            if (event.link && event.link.trim().length > 0) {
                eventTitle = <h3><a target="_blank" rel="noreferrer" href={event.link.trim()}>{event.title}</a></h3>;
                goToEvent = <a target="_blank" rel="noreferrer" className="cta-link" href={event.link.trim()}>GO TO EVENT</a>
            }

            let eventImage = '';
            if (event.image) {
                eventImage = <img alt={event.title} src={constants.CMS_URL + event.image.url} />
            }

            let description = (event.description) ? <li><br /><span>{event.description}</span></li> : '';
            let speakerItems = event.speaker.map((val, index) => {
                if (val && val.hasOwnProperty('title') > 0) {
                    return <span key={index}>{val.title}<br /></ span>
                }

                return null;

            }).filter(Boolean);

            // let eventDate = new Date(event.event_date).toUTCString();
            let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timezone: 'GMT', timeZoneName: 'short' };
            let eventDate = new Date(event.event_date).toLocaleString('en-GB', options);

            const searchClasses = (this.searchMode === true) ? 'results-info search-highlight' : 'results-info';

            content.push(
                (
                    <div className={searchClasses} key={index}>

                        <div className="event-content">
                            <div className="event-image">
                                {eventImage}
                            </div>
                            <div className="event-data">
                                {eventTitle}
                                <ul>
                                    <li><strong>Date: </strong><span>{eventDate}</span></li>
                                    <li><strong>Speaker: </strong><span>{speakerItems}</span></li>
                                    {description}
                                    <br />
                                    <li className="gotoevent">{goToEvent}</li>
                                </ul>
                            </div>
                            <div style={{ clear: "both" }}></div>
                        </div>
                    </div>
                )
            )
        }

        return content;
    }

    __loadPageContent = (limit, page = 1) => {


        //if we are on the search mode, do not send request to get content as they will be ignored anyway
        if (this.searchMode) {
            this.setState({
                events: this.__getSearchPage(page, this.state.searchPages)
            })
            return;
        }

        //get the us format for db, of the time set in dublin timezone
        var irishTimeStamp = getIrishTime();
        const sortDirection = (this.state.eventType.indexOf('future') !== -1) ? 'asc' : 'desc';
        let filterBy = (this.state.eventType.indexOf('future') !== -1) ? 'event_date_gte' : 'event_date_lte';

        //filter by event_date with reference to either future or past items,
        filterBy = `&${filterBy}=${irishTimeStamp}`;

        //filter by id if we have an id
        const _id = this.props._id;
        if (_id) {
            filterBy = `&id_in=` + _id;
        }

        const start = (page === 1) ? 0 : page * limit - limit;
        let queryDataTypes = [
            this.__getEvents(start, limit, filterBy, sortDirection, 'paginated_results'),
            this.__getEvents(0, 5000, filterBy, sortDirection, 'all_results')
        ];


        Promise.all(queryDataTypes)
            .then((results) => {
                if (this._isMounted) {

                    const toState = {}
                    results.forEach(result => {
                        if (result?.paginated_results) {
                            toState['events'] = result.paginated_results
                        } else if (result?.all_results) {
                            if (!this.state.total) {
                                toState['total'] = result.all_results.length;
                            }
                            toState['autocompleteEvents'] = result.all_results;

                        }
                    });

                    //if no current page, add it
                    if (!this.state.page) {
                        toState.page = getPageBySlug(this.props.mockLocation, this.state.allPages)
                    }
                    this.currentPgNo = page;
                    this.setState(toState);
                }
            })
            .catch((error) => {
                console.log('Error loading articles: ', error);
            });
    }

    __getEvents(pgStart, limit, filterType, sortDirection, key) {
        return axios.get(encodeURI(`${constants.EVENTS_API_URL}?_start=${pgStart}&_limit=${limit}&_sort=event_date:${sortDirection}${filterType}`)).then(response => ({ [key]: response.data }));
    }

    searchFilterClear = (event) => {
        const parent = event.target.closest('.eventssearch');
        const input = parent.querySelector('#cvri_events_search');
        input.value = null;
        // Create a new "change" event
        const changeEvent = new Event('change', {
            bubbles: true, // Allow the event to bubble up the DOM
            cancelable: true, // Allow the event to be canceled
        });

        input.dispatchEvent(changeEvent);
    }


    filterEventsBySearchValue = (event) => {
        const searchVal = event?.target?.value;

        if (!searchVal.trim()) {

            this.__setSearchMode(false);
            this.__loadPageContent(this.pageLimit);
            return;
        }

        this.__setSearchMode(true);


        const results = fuzzysort.go(searchVal, this.state.autocompleteEvents, {
            keys: ['description'],
            limit: 100,
            threshold: -100000,
        })

        const cleanResults = results.map(r => {
            return r.obj
        }).filter(Boolean);

        this.currentPgNo = 1;
        const searchPages = sliceIntoChunks(cleanResults, this.pageLimit, 1);

        this.setState({
            total: cleanResults.length,
            searchPages: searchPages,
            events: this.__getSearchPage(this.currentPgNo, searchPages)
        })

    }

    __getSearchPage(page, searchPages) {

        if (!searchPages)
            return [];

        if (searchPages.length >= page) {
            this.currentPgNo = page;
            return searchPages[page];
        }

        return [];
    }

}




export default CVRIForumEventPage;