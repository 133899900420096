import React from "react";
import { clickGoToFirstPage } from "../../utils/general";
import MenuItem from "./MenuItem";
import SearchBox from "./SearchBox";
import { withRouter} from "react-router-dom";
import 'hc-offcanvas-nav/dist/hc-offcanvas-nav.css';
import hcOffcanvasNav from 'hc-offcanvas-nav';


class Navigation extends React.Component {


    render() {

        const menuItems = this.props.menuItems;

        const renderMenuItems = [];
        for (const [index, menuItem] of menuItems.entries()) {
            renderMenuItems.push(<MenuItem key={index} data={menuItem} />);
        }

        return (
            <nav role="navigation" aria-label="Main Menu" className="nav-column search-box-item" id="main-nav">
                <ul id="gns_drop_down" role="menubar" aria-label="main menu" className="no-bullet">
                    {renderMenuItems}
                    <li className="phone-link d-block d-sm-none">
                        <a href={"tel:" + this.props.cleanPhoneNo} className="cta-phone">{this.props.phoneNo}</a>
                    </li>
                </ul>
                <SearchBox />
            </nav>
        )
    }

    componentDidMount() {
        //go to first page when we click a menu item
        clickGoToFirstPage();
        
        new hcOffcanvasNav('#main-nav', {
            disableAt: 640,
            customToggle: '.toggle',
            levelTitles: true,
            levelTitleAsBack: false,

        });
    
        this.__enableReactOffCanvasNav();
        this.__disableCategoryMenuItemClick();
       
    }

    /**
     * On off canvas nav, when a menu item is marked as a category, it won't respond to clicks. We replicate that on the desktop view.
     */
    __disableCategoryMenuItemClick = () => {
        const navgs = document.querySelectorAll('#main-nav a');
        for (var i = 0; i < navgs.length; ++i) {
            navgs[i].addEventListener("click", (event) => {

                let currentMenuItem = event.currentTarget;
                if (currentMenuItem.href && currentMenuItem.href.indexOf('#') !== -1) {
                    event.preventDefault();
                    return false;
                }
            });
        }
    }


    /**
     * Off canvas nav will copy the main navigation html, we need to catch clicks on that html and change the history
     */
    __enableReactOffCanvasNav = () => {
        const navgs = document.querySelectorAll('.nav-content a');

        for (var i = 0; i < navgs.length; ++i) {
            navgs[i].addEventListener("click", (event) => {

                let currentMenuItem = event.currentTarget;
                if (currentMenuItem.href && currentMenuItem.href.indexOf('tel:') !== -1) {
                    return;
                }

                event.preventDefault();
                this.props.history.push(event.currentTarget.pathname);
                return false;
            });
        }

    }
}

export default withRouter(Navigation);