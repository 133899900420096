import React from "react";
import HomePage from '../pages/HomePage';
import DefaultPage from '../pages/DefaultPage';
import NotFoundPage from '../pages/NotFoundPage';
import ArticleSinglePage from "./ArticleSinglePage";
import ContactPage from "./ContactPage";
import SearchPage from "./SearchPage";
import {getPageBySlug, getValidSlug, getQueryParameterByName, addEndingSlashToSlug} from "../../utils/general";
import {Route, Switch, withRouter} from "react-router-dom";
import * as constants from "../../constants/index";
import ArticlesPages from "./ArticlesPage";
import TeamMembersPage from "./TeamMembersPage";
import TeamMemberSinglePage from "./TeamMemberSinglePage";
import CVRIForumPage from "./CVRIForumPage";
import CVRIForumEventPage from "./CVRIForumEventPage";
import PublicationsPage from "./PublicationsPage";
import EducationPage from "./EducationPage";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import VideoPage from "./VideoPage";


class MainPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            allPages: this.props.pages,
            locationPathname: window.location.pathname
        }

        //pages that have submenu disabled. 
        this.DISABLE_TOP_PAGES = true;
    }


    render() {
        if (this.__shouldRedirectToSlashURL(this.state.locationPathname)) {
            console.log('Redirecting to page with ending slash... (You may be getting cannot render issues: add ending slash to URLs)');
            this.props.history.push(this.state.locationPathname + '/');
        }

        if (this.__shouldRedirectToNotFound()) {
            this.props.history.push('/404/');
        }

        let allPages = this.state.allPages;

        //normal pages. 
        return (
            <Switch>
                <Route exact path="/" >
                    <HomePage allPages={allPages} />
                </Route>
                <Route exact path="/404/">
                    <NotFoundPage />
                </Route>
                <Route exact path="*/contact-us/">
                    <ContactPage allPages={allPages} contact={this.props.contact} />
                </Route>
                {/**with this form of render we can pass on in history data from one component to the other */}
                <Route exact path="*/search/" render={ props => <SearchPage allPages={allPages} menuItems={this.props.menuItems} {...props} />} />
                <Route exact path={"*/" + constants.NEWS_PARENT_SLUG + "/"} >
                    <ArticlesPages allPages={allPages} menuItems={this.props.menuItems} />
                </Route>
                <Route path={"*/" + constants.NEWS_PARENT_SLUG + "/:slug/"} >
                    <ArticleSinglePage allPages={allPages} menuItems={this.props.menuItems} />
                </Route>
                <Route exact path="*/our-team/" >
                    <TeamMembersPage allPages={allPages} menuItems={this.props.menuItems}/>
                </Route>
                <Route exact path="*/about/our-team/:slug/" >
                    <TeamMemberSinglePage allPages={allPages} menuItems={this.props.menuItems}/>
                </Route>
                <Route exact path={"*/" + constants.CVRI_FORUM + "/"} >
                    <CVRIForumPage allPages={allPages} menuItems={this.props.menuItems} />
                </Route>
                <Route exact path={"*/" + constants.CVRI_FORUM + "/:event/"} >
                    <CVRIForumEventPage allPages={allPages} menuItems={this.props.menuItems}  mockLocation={"/" + constants.CVRI_FORUM + "/"} _id={getQueryParameterByName('_id')} key={String(getQueryParameterByName('_id'))} />
                </Route>
                <Route path={"*/publications/"} >
                    <PublicationsPage allPages={allPages} menuItems={this.props.menuItems} />
                </Route>
                <Route exact path={"*/privacy-policy/"} >
                    <PrivacyPolicyPage allPages={allPages}/>
                </Route>
                <Route exact path={"*/video/:slug/"} >
                    <VideoPage allPages={allPages}/>
                </Route>
                <Route exact path={"/" + constants.EDUCATION_PAGE + "/:slug?/"}>
                    <EducationPage allPages={allPages}  menuItems={this.props.menuItems} />
                </Route>
                <Route path="/:slug/" >
                    {/** key here is very important since we use the same component for different pages. */}
                    <DefaultPage allPages={allPages} menuItems={this.props.menuItems} key={this.state.locationPathname} />
                </Route>
            </Switch>
        )
    }


    componentDidMount() {
        
        this.__setDefaultPageTitle(this.props.locationPathname);
        
        this.unlisten = this.props.history.listen((location, action) => {
            this.__setDefaultPageTitle(location.pathname);

            if (this.DISABLE_TOP_PAGES) {
                this.__redirectToSubmenu(location.pathname);
            }

            let mainContent = document.getElementById('cvri_wrapper');
            if (mainContent) {
                //scroll to the top of the page. 
                mainContent.scrollIntoView(true);
            }

            return this.setState({
                locationPathname: location.pathname
            });
        });

    }

    /**
     * Sets the tab title. if empty leaves for the subpages to set the title. 
     * @param {string} pathname 
     */
    __setDefaultPageTitle = (pathname) => {
        
        if (!pathname || pathname.length === 0) {
            return false;
        }
 
        let currentPage = getPageBySlug(pathname, this.state.allPages);
        if (currentPage) {
            document.title = currentPage.title + ' - CVRI';
        }
        
    }

    /**
     * If page has sub-pages, redirect to the first sub page. 
     * @param {String} pathname 
     */
    __redirectToSubmenu = (pathname) => {

        let pageSlug = getValidSlug(pathname);
        let page = getPageBySlug(pageSlug, this.props.menuItems);

        if (page && page.hasOwnProperty('sub_pages') && page['sub_pages']) {
            let firstSubPage = page['sub_pages'][0];
            if (firstSubPage) {
                let redirectionPath = addEndingSlashToSlug(firstSubPage.full_slug);
                this.props.history.push(redirectionPath);
            }
        }
    }

    __shouldRedirectToSlashURL = (pathname) => {
        //if path has no ending slash, redirect to path with ending slash
        if (pathname.substr(-1) !== '/'){
            return true;
        }

        return false;
    }


    __shouldRedirectToNotFound = () => {
        let page = getPageBySlug(this.state.locationPathname, this.state.allPages);
        let currentSlug = getValidSlug(this.state.locationPathname);

        let basePathParts = this.state.locationPathname.split('/').filter(Boolean);

        let basePath = '';
        if (basePathParts.length > 0) {
            //remove last element which must be the item's slug
            basePathParts.pop();
            //recreate the slug
            basePath = basePathParts.join('/');
        } 

        let exceptions = ['/', '404'];

        //do not redirect if base slug/path is articles/or news or whatever...
        let basePathExceptions = [constants.NEWS_PARENT_SLUG, constants.EDUCATION_PAGE, constants.TEAM_MEMBERS_PARENT_SLUG, constants.CVRI_FORUM, constants.VIDEOS_SINGLE_PARENG_SLUG];

        if (exceptions.includes(currentSlug) || basePathExceptions.includes(basePath) || page ) {
            return false;
        } else {
            return true;
        }
    }

    componentWillUnmount() {
        if ( typeof this.unlisten !== 'function'){
            console.log('this.unlisten() does not exist');
            return;
        }
        this.unlisten();
    }
}

export default withRouter(MainPage);