import React from "react";
import {Link} from "react-router-dom";
import {addEndingSlashToSlug} from "../../utils/general";


class MenuItem extends React.Component {

    render() {
        const menuItem = this.props.data;
        
        if (!menuItem) {
            return false;
        }

        //if there are sub pages then return the component recursively
        if (menuItem.hasOwnProperty('sub_pages') && menuItem["sub_pages"].length > 0){

            const subMenuItems = [];
            for (const [index, subMenuItem] of menuItem["sub_pages"].entries()) {
                subMenuItems.push(<MenuItem key={index} data={subMenuItem} class="dropdown-item" slug={menuItem.full_slug} />)
            }

            const topPagesSlug = (menuItem.as_category === true) ? {pathname: '#'}: addEndingSlashToSlug(menuItem.full_slug);

            return (
                <li className="dropdown dropdown-with-submenus">
                    <Link className="dropdown-toggle" data-display="static" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" to={topPagesSlug} touch-action="auto">{menuItem.title}
                        <span className="down-arrow" />
                    </Link>
                    <ul role="menu">
                        {subMenuItems}
                    </ul>
                </li>
            )
        } else {

            let menuSlug = addEndingSlashToSlug(menuItem.full_slug);
            let linkClassName;
            if (this.props.hasOwnProperty("class")) {
                linkClassName = this.props.class;
            }

            return (
                <li>
                    <Link className={linkClassName} data-display="static" role="button" aria-haspopup="true" aria-expanded="false" to={`${menuSlug}`} touch-action="auto">{menuItem.title}</Link>
                </li>
            )
        }


    }
}

export default MenuItem;