import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import CVRI from "./components/CVRI";


ReactDOM.render(
  <React.StrictMode>
    <CVRI />
  </React.StrictMode>,
  document.getElementById('cvri_wrapper')
);
