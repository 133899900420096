import React from "react";
import * as constants from '../../constants';
import SanitizedContent from "./SanitizedContent";
import SideBar from "./SideBar";


class PageContent extends React.Component {


    render() {

        const currentPage = this.props.currentPage;

        let pageContent = '';
        if (this.props.content) {
            pageContent = this.props.content;
        } else if (currentPage) {
            let currentPageDescription = currentPage.description;
            pageContent = (currentPageDescription)? currentPageDescription: '';
        } else {
            pageContent = '';
        }

        let imageObject = null;
        if (currentPage && currentPage.hasOwnProperty("image") && currentPage.image && currentPage.image.hasOwnProperty('url') && currentPage.image.url.length > 0) {
            imageObject = <img id="article-main-image" alt={currentPage.title} src={constants.CMS_URL + currentPage.image.url} />
        }

        return (
            <>
            <main id="main-content" className="inner-main spotlight-landing-layout template-no-gaps">
                <section id="spotlight-area" className="container">
                    <div className="cvri-sidebar-content-wrapper">
                        <div>
                            <SideBar menuItems={this.props.menuItems} />
                        </div>
                        <div>
                            {imageObject}
                            <SanitizedContent content={pageContent} />
                        </div>
                    </div>
                </section>
                <div className="clear"></div>
            </main>
          </>
        )
    }

}

export default PageContent;