import React from "react";
import * as constants from '../../constants';
import Title from '../page_parts/Title';
import Breadcrumb from '../page_parts/Breadcrumb';
import axios from "axios";
import {getPageBySlug, urlQuerySearchToObject, getValidSlug} from '../../utils/general';
import CVRIPagination from '../page_parts/CVRIPagination';
import SideBar from "../page_parts/SideBar";
import { withRouter} from "react-router-dom";

class PublicationsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            allPages: this.props.allPages,
            page: null, //rather than querying the db to set this, we set it from the props.allPages as we don't need content of the page.
            items: [],
            total: 0,
            topTags: []
        }

        this.pageLimit = 10;
        this.currentPgNo = 1;
        this.appliedTags = this.__getAppliedTags();
    }

    render() {

        if (!this.state.page) {
            return null;
        }

        return (
            <>
                <Breadcrumb allPages={this.state.allPages} />
                <Title currentPage={this.state.page} />
                {this.__getContent()}
            </>

        )
    }


    componentDidMount() {
        this._isMounted = true;
        this.__loadPageContent(this.pageLimit);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    __getContent = () => {
        
        let content = [];

        for (const [index, item] of this.state.items.entries()) {

            const description = (item.description)? <p><b>Description:</b> {item.description}</p>: '';
            let publication_date = '';
            if (item.publication_date) {
                let pub_date = new Date(item.publication_date);
                let pub_date_formated = pub_date.toLocaleString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
                publication_date = <p><b>Publication Date</b>: {pub_date_formated}</p>;
            }
            let itemLink = '';
            let itemFiles = [];
            let itemTags = [];
            

            if (item.link) {
                itemLink = <a className="btn btn-secondary btn-forum btn-file-download" target="_blank" rel="noreferrer" href={item.link}>Get to Publication</a>
            }

            if (item.file) {
                itemFiles = item.file.map((val, index) => {
                    let fileTitle = (val.name)? val.name: val['file']['name'];
                    let fileUrl = constants.CMS_URL + val['file']['url'];
                    return <a key={index} className="btn btn-secondary btn-file-download" href={fileUrl} onClick={(e) => {this.__download(fileUrl, val['file']['name']); e.preventDefault()}} >{fileTitle}</a>;
                }).filter(Boolean);
            }

            if (item.tags.length > 0) {
                itemTags = item.tags.map((val, index) => {
                    let tag = val.tag;

                    if (typeof tag === 'undefined' || !tag) {
                        return null;
                    }

                    let appliedTagClass = (this.appliedTags.includes(tag)) ? 'applied': '';
                    let addRemoveAction = (this.appliedTags.includes(tag)) ? 'remove': 'add';
                    return <li key={index} ><a href="#/" className={appliedTagClass + " tag"} onClick={(e) => {this.__addRemoveTagFromUrl(e, addRemoveAction)}}>{tag}</a></li>;
                }).filter(Boolean);
            }

            content.push(
                (
                <div className="results-info" key={index}>
                    <h3>{item.title}</h3>
                    
                    <div className="article-content">
                        {publication_date}
                        {description}
                        {itemTags.length > 0 &&<ul className="tags">
                            <li><b>Tags:</b> &nbsp; &nbsp; </li>
                            {itemTags}
                        </ul>}
                        {itemLink}
                        {itemFiles}
                    </div>
                    <div className="clear"></div>
                </div>
                )
            )
        }

        // const pageAppliedTags = this.appliedTags.map((tag, index) => {
        //     return (
        //         <li key={index} ><a href="#/" className={"applied tag"} onClick={(e) => {this.__addRemoveTagFromUrl(e, 'remove')}}>{tag}</a></li>
        //     )
        // });


        const pageTopTags = this.state.topTags.map((tagObject, index) => {
            if (typeof tagObject.tag === 'undefined' || !tagObject.tag) {
                return null;
            }
            let addRemoveAction = (this.appliedTags.includes(tagObject.tag)) ? 'remove': 'add';
            let appliedTagClass = (this.appliedTags.includes(tagObject.tag)) ? 'applied': '';
            let dataWeight = (tagObject.total > 9) ? 9 : tagObject.total;
            return (
                <li key={index} data-weight={dataWeight} ><a href="#/" className={"tag " + appliedTagClass} onClick={(e) => {console.log('touched', e);this.__addRemoveTagFromUrl(e, addRemoveAction)}}>{tagObject.tag}</a></li>
            )
        });

        return (
           <main id="main-content" className="inner-main spotlight-landing-layout template-no-gaps col-sm articlespage">
                <section id="spotlight-area" className="container">
                <div className="cvri-sidebar-content-wrapper">
                    <div>
                        <SideBar menuItems={this.props.menuItems} />
                    </div>
                    <div id="publications-page">
                        <div id="publications-tag-cloud">
                            {pageTopTags.length > 0 && <ul className="tags">
                                <li><b>Popular Tags:</b> &nbsp; &nbsp; </li>
                                {pageTopTags}
                            </ul>}
                        </div>
                        <div>
                            {content}
                            <CVRIPagination currentPgNo={this.currentPgNo} type="publications" total={this.state.total} pageLimit={this.pageLimit} loadPageContent={this.__loadPageContent} />
                        </div>
                    </div>
                </div>
            </section>
        </main>
        )
    }

    //force windows to open save as .
    __download = (url, title) => { 
        axios({ 
            url:url, 
            method:'GET', 
            responseType: 'blob' 
        }) 
        .then((response) => { 

            if(window.navigator.msSaveOrOpenBlob) {
                // IE11
                window.navigator.msSaveOrOpenBlob(new Blob([response.data]), title);
            } else {

                // Google chome, Firefox, ....
                const url = window.URL 
                .createObjectURL(new Blob([response.data])); 
                    const link = document.createElement('a'); 
                    link.href = url; 
                    link.setAttribute('download', title); 
                    document.body.appendChild(link); 
                    link.click(); 
            }
        }) 
    }

    __loadPageContent = (limit, page=1) => {

        let queryDataTypes = [this.__getItems(page, limit)];

        //query the total items only if we have no total
        //update always get the total items, as tags may be applied.
        queryDataTypes.push(this.__getTotalItems());

        if (!this.state.page) {
            queryDataTypes.push(this.__getCurrentPageContent())
        }

        if (this.state.topTags.length === 0) {
            queryDataTypes.push(this.__getTopTags())
        }

        axios.all(queryDataTypes)
        .then(axios.spread((...args) => {
            let publicationData = {};
            for (let i = 0; i < args.length; i++) {
                let requestURL = args[i].config.url;
                if (requestURL.indexOf('get_by_tags?get_total=true') !== -1) {
                    publicationData['get_by_tags_get_total'] = args[i].data;
                    continue;
                }
                let urlNoArgs = requestURL.split('?')[0].replace(/\/$/, "");
                let lastPartUrl = urlNoArgs.substring(urlNoArgs.lastIndexOf('/') + 1);
                publicationData[lastPartUrl] = args[i].data;
            }
            return publicationData;
         }))
        .then((results) => {
            if (this._isMounted) {

                let toState = {
                    items: results.get_by_tags,
                    // total: null, //enabling these will result in a blank page when going to the second page.
                    // page: null
                }

                //add total only if it exists
                if (results.get_by_tags_get_total) {
                    toState.total = results.get_by_tags_get_total;
                }

                if (results.pages) {
                    toState.page = results.pages[0];
                } else if (!this.state.page) {
                    toState.page = getPageBySlug(window.location.pathname, this.state.allPages)
                }

                if (results.get_top_tags) {
                    toState.topTags = this.shuffle(results.get_top_tags);
                }

                this.currentPgNo = page;

                this.setState(toState);
            }
        })
        .catch((error) => {
            console.log('Error loading articles: ', error);
        });

    }

    shuffle = (array) =>{
        let currentIndex = array.length,  randomIndex;

        // While there remain elements to shuffle...
        while (currentIndex !== 0) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }

        return array;
      }
      

    
    __getItems(pgStart, limit) {
        let tags = this.appliedTags
        tags = JSON.stringify(tags)
        return axios.get(encodeURI(`${constants.PUBLICATIONS_API_URL}?page=${pgStart}&limit=${limit}&tags=${tags}`));
    }

    __getCurrentPageContent() {
        const currentSlug = getValidSlug(window.location.pathname);
        return axios.get(encodeURI(constants.PAGES_API_URL + '?slug='+currentSlug));
    }

    __getTopTags() {
        return axios.get(encodeURI(constants.PUBLICATIONS_TOP_TAGS_URL));
    }

    __getTotalItems() {
        let tags = this.appliedTags
        tags = JSON.stringify(tags)
        return axios.get(constants.PUBLICATIONS_API_URL + '?get_total=true&tags=' + tags);
    }

    __getAppliedTags = () => {
        let tags = [];
        let querySearchObj = urlQuerySearchToObject();
        if (querySearchObj.hasOwnProperty('tags')) {
            tags = JSON.parse(querySearchObj.tags);
        }
        return tags
    }

    __addRemoveTagFromUrl = (e, type="add") => {
        let tag = e.target.textContent;

        let querySearchObj = urlQuerySearchToObject();

        let newTagsProperty = [tag];
       
        if (querySearchObj.hasOwnProperty('tags')) {
            newTagsProperty = JSON.parse(querySearchObj.tags);
            if (type === 'add') {
                if (!newTagsProperty.includes(tag)) {
                    newTagsProperty.push(tag);
                }
            } else {
                newTagsProperty = newTagsProperty.filter(value => value !== tag)
            }
        }
        this.appliedTags = newTagsProperty;
        this.__loadPageContent(this.pageLimit)
        newTagsProperty = JSON.stringify(newTagsProperty);
        querySearchObj['tags'] = newTagsProperty;
        let queryString = Object.keys(querySearchObj).map(key => key + '=' + querySearchObj[key]).join('&');
        this.props.history.push("?" + queryString);
        
    }

}

export default withRouter(PublicationsPage);

