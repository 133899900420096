import React from "react";
import Title from '../page_parts/Title';
import Breadcrumb from '../page_parts/Breadcrumb';
import Axios from "axios";
import * as constants from '../../constants';
import {getValidSlug} from '../../utils/general';
import SanitizedContent from "../page_parts/SanitizedContent";
import {Link} from "react-router-dom";
import SideBar from "../page_parts/SideBar";


class CVRIForumPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            allPages: this.props.allPages,
            page: null
        }
    }

    render() {

        if (!this.state.page) {
            console.log('no page content yet');
            return null;
        }
        
        let currentPage = this.state.page;
        let imageObject = null;
        if (currentPage && currentPage.hasOwnProperty("image") && currentPage.image.hasOwnProperty('url') && currentPage.image.url.length > 0) {
            imageObject = <img id="article-main-image" alt={currentPage.title} src={constants.CMS_URL + currentPage.image.url} />
        }

        return (
            <>
                <Breadcrumb allPages={this.state.allPages} />
                <Title currentPage={this.state.page} />
                <main id="main-content" className="inner-main spotlight-landing-layout template-no-gaps">
                    <section id="spotlight-area" className="container">
                    <div className="cvri-sidebar-content-wrapper">
                        <div>
                            <SideBar menuItems={this.props.menuItems} />
                        </div>
                        <div>
                            {imageObject}
                            <SanitizedContent content={currentPage.description} />
                            {this.__getEvents()}
                        </div>
                    </div>
                    </section>
                </main>
            </>

        )

    }

    componentDidMount() {
        this._isMounted = true;
        this.__loadPageContent();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    

    __loadPageContent = () => {

        const currentSlug = getValidSlug(window.location.pathname);

        Axios.get(constants.PAGES_API_URL + '?slug='+currentSlug)
            .then(
                (result) => {
                    if (this._isMounted) {
                        let pageData = result.data;

                        if (!pageData || pageData.length === 0) {
                            //no page found: redirect
                            return this.setState({
                                redirect: true
                            })
                        }

                        //if data is an array work with that. 
                        if (Array.isArray(result.data) && result.data.length > 0) {
                            pageData = result.data[0];
                        }
                    
                        this.setState({
                            page: pageData
                        });
                    }
                    
                }
            )
            .catch((error) => {
                // handle error
                console.log('Error fetching the page content: ', error);
            }
        );
    }

    __getEvents = () => {
        return (
            <div className="cvri-forum">
                <div className="past-events"> 
                    <Link className="spotlight" to={`${constants.FORUM_PAST_EVENTS}/`}>
                        <div className="image-wrapper ">
                            <img src={process.env.PUBLIC_URL + '/images/cvri_past_events.png'} alt="Past Events"/>
                        </div>
                        <div className="spot-content ">
                            <span className="spot-title">Past Events</span>
                        </div>
                    </Link>
                </div>
                <div className="future-events">
                    <Link className="spotlight" to={`${constants.FORUM_FUTURE_EVENTS}/`}>
                        <div className="image-wrapper ">
                            <img src={process.env.PUBLIC_URL + '/images/cvri_future_events.png'} alt="Future Events" />
                        </div>
                        <div className="spot-content ">
                            <span className="spot-title">Upcoming Events</span>
                        </div>
                    </Link>
                </div>
            </ div>
        )
    }

}




export default CVRIForumPage;