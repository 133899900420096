import React from "react";
import Title from '../page_parts/Title';
import Breadcrumb from '../page_parts/Breadcrumb';
import axios from "axios";
import * as constants from '../../constants';
import {getValidSlug, getPageBySlug, stringToSlug} from '../../utils/general';
import { withRouter} from "react-router-dom";
import ReactJWPlayer from 'react-jw-player';


class VideoPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            allPages: this.props.allPages,
            page: false,
            playlistItems: [],
            player: null,
            total: 0,
        }

        this.JWPlayerID = 'dfasdfasdfa!df';
        this.player = null;
        this.playVideo = false;
        this.currentVideo = false;

        this.pageLimit = 10;
        this.currentPgNo = 1;
    }

    render() {

        const playlistItems = this.state.playlistItems;

        if (!this.state.page || !playlistItems) {
            console.log('no page content yet');
            return null;
        }

        const currentVideo = this.__getVideoByCurrentSlug();

        if (!currentVideo) {
            // redirect to 404
            this.props.history.push('/404/');
            return null;
        }
    
        const pageData = this.state.page;
        pageData.title = currentVideo.title;

        const topSection = (
            <ReactJWPlayer
                playerId={this.JWPlayerID}
                playerScript={constants.CDN_JWPLAYER_URL}
                isAutoPlay={false}
                isMuted={false}
                playlist={[currentVideo]}
            />  
        )

        let videoDescription = null;
        let mainVideoContWidth = 'col-xs-12 col-md-12 main_video_sub';

        if (currentVideo.description) {

            videoDescription = (
                <div className="col-xs-12 col-md-4">
                    <div className="card-component" id="m_video_details">
                    <div className="card-heading"></div>
                        {currentVideo.description}
                    </div>
                </div>
            )
            mainVideoContWidth = 'col-xs-12 col-md-8 main_video_sub';
        }


        return (
            <>
                <Breadcrumb allPages={this.state.allPages} extra={currentVideo} />
                <Title currentPage={pageData} />
                <section id="spotlight-area" className="container">
                <>
                <main id="main-content" className="inner-main spotlight-landing-layout template-no-gaps">
                    <section className="full-video-container single">
                        <div className="row no-gutter m_main_video">
                            <div className={mainVideoContWidth}>
                                <div id="m_main_video">{topSection}</div>    
                            </div>
                            {videoDescription}
                        </div>
                    </section>
                </main>
                </>
                </section>                
            </>
        )
    }

    __getVideoByCurrentSlug = (inputPlaylistItems = []) => {

        const playlistItems = (inputPlaylistItems && inputPlaylistItems.length > 0)? inputPlaylistItems: this.state.totalItems;

        let slug = getValidSlug(window.location.pathname);
        let selectedVideo = playlistItems.map((video, index) => {
            return (video.slug === slug) ? video: '';
        }).filter(Boolean);

        if (selectedVideo.length > 0) {
            return selectedVideo[0];
        }

        return false;
    }

    /**
     * Returns the page from a playlist. 
     * if there's a video url it will return the page where that video has been partitioned in. 
     * @param {Array} inputPlaylist 
     * @param {Integer} limit 
     * @param {Integer} page 
     */
    __getPlaylistPage = (inputPlaylist, limit, page=1) => {

        if (!inputPlaylist) {
            return [];
        }

        //copy the playlist. 
        const playlist = JSON.parse(JSON.stringify(inputPlaylist));
        const currentVideo = this.__getVideoByCurrentSlug(playlist);

        const pages = new Array(Math.ceil(playlist.length / limit))
            .fill()
            .map(_ => playlist.splice(0, limit));


        let pageIndex = page-1;
        //overwrite pageIndex, is we have a video slug in the url. 
        if (currentVideo) {
            for ( const [page_index, pagePlaylist] of pages.entries()) {
                for (const pageItem of pagePlaylist) {
                    if (pageItem.playlist_index === currentVideo.playlist_index) {
                        pageIndex = page_index;
                        page = pageIndex + 1;
                    }
                }
            }
        }        

        this.currentPgNo = page;
        
        return pages[pageIndex];
    }


    componentDidMount() {
        this._isMounted = true;
        this.__loadPageContent();

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    __loadPageContent = () => {
        
        let queryDataTypes = [this.__getTotalItems()];

        axios.all(queryDataTypes)
        .then((results) => {
            if (this._isMounted) {

                let toState = {
                    playlistItems: [],
                    totalItems: []
                }

                let playlist = results[0].data.playlist;

                if (playlist.length > 0) {
    
                    for (let [index, video] of playlist.entries()) {
                        playlist[index].slug = (video.hasOwnProperty('custom_slug')) ? video.custom_slug : stringToSlug(video.title);
                        playlist[index].playlist_index = index;
                    }
                    
                    toState.totalItems = playlist;
                    toState.playlistItems = this.__getPlaylistPage(playlist, this.pageLimit);
                    toState.total = playlist.length
                }

                //if no current page, add it
                if (!this.state.page) {
                    toState.page = getPageBySlug(getValidSlug(constants.VIDEOS_SINGLE_PARENG_SLUG), this.state.allPages)
                }
                // this.currentPgNo = page;

                this.setState(toState);
            }
        })
        .catch((error) => {
            console.log('Error loading articles: ', error);
        });

    }

    __getTotalItems() {
        return axios.get(constants.JWP_SINGLE_VIDEO_URL);
    }


}

export default withRouter(VideoPage);