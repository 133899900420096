import React from "react";
import Title from '../page_parts/Title';
import Breadcrumb from '../page_parts/Breadcrumb';
import axios from "axios";
import * as constants from '../../constants';
import {getValidSlug, getPageBySlug, addEndingSlashToSlug, stringToSlug} from '../../utils/general';
import { withRouter} from "react-router-dom";
import VideoPlaylist from "../page_parts/VideoPlaylist";
import CVRIPagination from "../page_parts/CVRIPagination";
import SideBar from "../page_parts/SideBar";


class EducationPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            allPages: this.props.allPages,
            page: false,
            playlistItems: [],
            player: null,
            total: 0,
        }

        this.JWPlayerID = 'f34rfdsax34';
        this.player = null;
        this.playVideo = false;
        this.currentVideo = false;

        this.pageLimit = 10;
        this.currentPgNo = 1;
    }

    render() {

        const playlistItems = this.state.playlistItems;

        if (!this.state.page || !playlistItems) {
            console.log('no page content yet');
            return null;
        }

        const currentVideo = this.__getVideoByCurrentSlug();

        return (
            <>
                <Breadcrumb allPages={this.state.allPages} extra={currentVideo} />
                <Title currentPage={this.state.page} />
                <section id="spotlight-area" className="container">
                    <div className="cvri-sidebar-content-wrapper">
                        <div>
                            <SideBar menuItems={this.props.menuItems} />
                        </div>
                        <div>
                            <VideoPlaylist playlist={playlistItems} currentVideo={currentVideo} />
                            <section className="container">
                                <CVRIPagination currentPgNo={this.currentPgNo} type="education items" total={this.state.total} pageLimit={this.pageLimit} loadPageContent={this.__loadPageContent} activatePagingLinks={this.__activatePagingLinks} />
                            </section>
                        </div>
                    </div>
                </section>                
            </>
        )
    }

    __getVideoByCurrentSlug = (inputPlaylistItems = []) => {

        const playlistItems = (inputPlaylistItems && inputPlaylistItems.length > 0)? inputPlaylistItems: this.state.totalItems;

        let slug = getValidSlug(window.location.pathname);
        let selectedVideo = playlistItems.map((video, index) => {
            return (video.slug === slug) ? video: '';
        }).filter(Boolean);

        if (selectedVideo.length > 0) {
            return selectedVideo[0];
        }

        return false;
    }


    /**
     * Returns the page from a playlist. 
     * if there's a video url it will return the page where that video has been partitioned in. 
     * @param {Array} inputPlaylist 
     * @param {Integer} limit 
     * @param {Integer} page 
     */
    __getPlaylistPage = (inputPlaylist, limit, page=1) => {

        if (!inputPlaylist) {
            return [];
        }

        //copy the playlist. 
        const playlist = JSON.parse(JSON.stringify(inputPlaylist));
        const currentVideo = this.__getVideoByCurrentSlug(playlist);

        const pages = new Array(Math.ceil(playlist.length / limit))
            .fill()
            .map(_ => playlist.splice(0, limit));


        let pageIndex = page-1;
        //overwrite pageIndex, is we have a video slug in the url. 
        if (currentVideo) {
            for ( const [page_index, pagePlaylist] of pages.entries()) {
                for (const pageItem of pagePlaylist) {
                    if (pageItem.playlist_index === currentVideo.playlist_index) {
                        pageIndex = page_index;
                        page = pageIndex + 1;
                    }
                }
            }
        }        

        this.currentPgNo = page;
        
        return pages[pageIndex];
    }


    componentDidMount() {
        this._isMounted = true;
        this.__loadPageContent();

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    __loadPageContent = () => {
        
        let queryDataTypes = [this.__getTotalItems()];

        axios.all(queryDataTypes)
        .then((results) => {
            if (this._isMounted) {

                let toState = {
                    playlistItems: [],
                    totalItems: []
                }

                let playlist = results[0].data.playlist;

                if (playlist.length > 0) {
    
                    for (let [index, video] of playlist.entries()) {
                        playlist[index].slug = stringToSlug(video.title);
                        playlist[index].playlist_index = index;
                    }
                    
                    toState.totalItems = playlist;
                    toState.playlistItems = this.__getPlaylistPage(playlist, this.pageLimit);
                    toState.total = playlist.length
                }

                //if no current page, add it
                if (!this.state.page) {
                    toState.page = getPageBySlug(getValidSlug(constants.EDUCATION_PAGE), this.state.allPages)
                }
                // this.currentPgNo = page;

                this.setState(toState);
            }
        })
        .catch((error) => {
            console.log('Error loading articles: ', error);
        });

    }

    __getItems(pgStart, limit) {
        return axios.get(encodeURI(`${constants.JWP_PLAYLIST_URL}?page_offset=${pgStart}&page_limit=${limit}`));
    }

    __getTotalItems() {
        return axios.get(constants.JWP_PLAYLIST_URL);
    }

    /**
     * Overwrites the pagination paging links function
     * @param {Object} event 
     * @param {Integer} currentPgNo 
     */
    __activatePagingLinks = (event, currentPgNo) => {
        
        const limit = this.pageLimit;

        this.props.history.push('/' + addEndingSlashToSlug(constants.EDUCATION_PAGE));
        
        if (!event.target.classList.contains('disabled')){
            let playlist = this.state.totalItems;
            let playlistItems = this.__getPlaylistPage(playlist, limit, currentPgNo);

            this.setState({
                playlistItems: playlistItems
            });
        }
        
        event.preventDefault();
    }
}

export default withRouter(EducationPage);