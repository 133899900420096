export const FOOTER_CONTACT_TYPE = 'contact';
export const FOOTER_LOGOS_TYPE = 'logos';
export const FOOTER_LINKS_TYPE = 'links';
export const FOOTER_SOCIALS_TYPE = 'socials';

//the parent path/slug of the news/articles items
export const NEWS_PARENT_SLUG = 'about/news';
export const VIDEOS_PARENT_SLUG = 'videos';
export const VIDEOS_SINGLE_PARENG_SLUG = 'video';
export const TEAM_MEMBERS_PARENT_SLUG = 'about/our-team';
export const EDUCATION_PAGE = 'education-and-publication/education';
export const CVRI_FORUM = 'education-and-publication/dublin-cardiovascular-research-forum';
export const CVRI_PATENT_ID = 41;

export const FORUM_FUTURE_EVENTS = 'future-events';
export const FORUM_PAST_EVENTS = 'past-events';

//RESEARCH page links
export const RESEARCH_PARENT_SLUG = 'research';
export const RESEARCH_OUTCOMES_RESEARCH = 'outcomes-research';
export const RESEARCH_CLINICAL_TRIALS = 'clinical-trials';
export const RESEARCH_CORE_LAB = 'core-lab';
export const RESEARCH_SYSTEMATIC_REVIEW = 'systematic-review';
export const ELECTROPHYSIOLOGY_RESEARCH = 'electrophysiology-research';