import React from "react";
import * as constants from '../../constants';
import {Link} from "react-router-dom";
import {addEndingSlashToSlug} from "../../utils/general";

class FooterSection extends React.Component {

    render () {
        return this.__getSectionBody();
    }

    __getSectionBody = () => {

        const data = this.props.data;

        const fsType = data.type;

        const links = data.link;
        
        let fsData = null;
        switch (fsType) {
                
            // case constants.FOOTER_ADDRESS_TYPE:
                
            //     fsData =  (
            //         <div className="col-sm-3">
            //             <span className="h4">{data.title}</span>
            //             <address>
            //             {data.description}
            //             </address>
            //         </div>
            //     )
            // break;
            case constants.FOOTER_CONTACT_TYPE:
                let contactItems = links.map((item, index) => {
                    let type = item.label;
                    switch (type) {
                        case 'phone':
                            return <li key={index}><a href={"tel: " + item.value.replace(/ /g, "")} className="cta-phone">{item.value}</a></li>
                        case 'email': 
                            return <li key={index}><a key={index} href={"mailto: " + item.value} className="cta-email">{item.value}</a></li>
                        default:
                            return null;
                    }
                });

                fsData = (
                    <div className="col-sm-3 footer-contact">
                        <span className="h4">{data.title}</span>
                        <ul className="no-bullet">
                            {contactItems}
                        </ul>
                  </div>
                )

            break;
            case constants.FOOTER_LINKS_TYPE:

                let quickLinksItems= links.map((item, index) => {
                    return <li key={index}><Link to={addEndingSlashToSlug(item.value)}>{item.label}</Link></li>;
                });

                fsData = (
                    <div className="col-sm-3">
                    <span className="h4">{data.title}</span>
                    <ul className="no-bullet">
                        {quickLinksItems}
                    </ul>
                  </div>
                )
            break;
            case constants.FOOTER_SOCIALS_TYPE:
                // fsData = <></>;
                let socialItems = links.map((item, index) => {
                    return <li key={index}><a href={item.value} target="_blank" rel="noopener noreferrer" className="external-link"><span className="sr-only">{item.label}</span></a></li>;
                });

                fsData = (
                    <div className="col-sm-3">
                        <ul className="social-links-list no-bullet">
                            {socialItems}
                        </ul>
                    </div>
                )

            break;
            case constants.FOOTER_LOGOS_TYPE:
                let footerLogos =  links.map((logoItem, index) => {

                    let logoUrl = null;

                    if (!logoItem.image) {
                        return logoUrl;
                    }

                    if (logoItem.value.indexOf('http') !== -1) {
                        logoUrl = (
                            <a href={logoItem.value} key={index}  target="_blank" rel="noreferrer">
                                <img src={constants.CMS_URL + logoItem.image.url}  alt={logoItem.label} />
                            </a>
                        );
                    } else if(logoItem.value && logoItem.value.length > 0) {
                        logoUrl = (
                            <Link to={logoItem.value} key={index}>
                                <img src={constants.CMS_URL + logoItem.image.url} alt={logoItem.label} />
                            </Link>
                        )
                    }

                    return logoUrl;
                });

                fsData =  (
                    <div className="col-sm-3">
                        <div className="logo-item">
                            {footerLogos}
                        </div>
                    </div>
                )
                break;
            default:
                fsData = null;
            break;
        }

        return fsData;
    }
}

export default FooterSection;