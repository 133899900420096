import React from "react";
import axios from "axios";
import * as constants from '../constants';
import Header from './page_parts/Header';
import Footer from './page_parts/Footer';
import {BrowserRouter as Router} from "react-router-dom";
import MainPage from './pages/MainPage';
import TopLinks from './page_parts/TopLinks';
import AskAQuestion from './page_parts/AskAQuestion';


class CVRI extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            menuItems: [],
            allPages: [],
            contact: [],
            footer: []
        };
    }

    componentDidMount() {
        axios.all([this.__getMenuItems(), this.__getFooterData(), this.__getPagesList(), this.__getContactData()])
        .then((results) => {
            this.setState({
                menuItems: results[0].data,
                footer: results[1].data,
                allPages: results[2].data,
                contact: results[3].data,
                loading: false
            });
        })
        .catch((error) => {
            console.log('Error loading initial data: ', error);
        });
    }


    render() {

        //make sure we have the menu items before we render anything on the page. 
        if (this.state.loading) {
            console.log('Loading Data..');
            return null;
        }

        const menuItems = this.state.menuItems;
        const footerData = this.state.footer;

        return (
            <Router>
                <TopLinks type="mobile" pages={this.state.allPages} />
                <div id="wrapper">
                    <Header menuItems={menuItems} data={footerData} contact={this.state.contact} pages={this.state.allPages} />
                    <MainPage menuItems={menuItems} pages={this.state.allPages} contact={this.state.contact} />
                    <Footer data={footerData} />
                    <AskAQuestion />
                </div>
            </Router>
        );

    }


    __getMenuItems() {
        return axios.get(constants.NAVIGATION_API_URL);
    }

    __getFooterData() {
        return axios.get(constants.FOOTER_API_URL);
    }

    __getPagesList() {
        return axios.get(constants.PAGES_API_LIST);
    }

    __getContactData() {
        return axios.get(constants.CONTACT_API_URL);
    }
}



export default CVRI;