import React from "react";
import {Link} from "react-router-dom";
import * as constants from "../../constants/index";
import {getPageBySlug, getValidSlug, getPageByID, addEndingSlashToSlug} from '../../utils/general';

class TopLinks extends React.Component {

    render() {


        let type = (this.props.type) ? this.props.type: 'mobile';

        // const referralLink = '/send-a-referral/';
        // const bookAppointmentLink = '/book-an-appointment/';
        // const referralName = 'Referral';
        // const appointmentName = 'Dublin Cardiovascular Research Forum';


        // let cleanPhoneNo = this.props.cleanPhoneNo;
        // let phoneNo = this.props.phoneNo;

        let forumPageSlug = '/' + constants.CVRI_FORUM + '/';
        let forumPage = getPageBySlug(getValidSlug(forumPageSlug), this.props.pages);
        let patientPage = getPageByID(constants.CVRI_PATENT_ID, this.props.pages);

        let links = (
            <div className="cvri-nav-column">
                <ul id="tns_drop_down" className="no-bullet">
                    <li><Link to={"/" + addEndingSlashToSlug(patientPage.slug)} className="btn btn-primary mr-1 btn-forum">{patientPage.title}</Link></li>
                    <li><Link to={forumPageSlug} className="btn btn-secondary btn-forum">{forumPage.title}</Link></li>
                </ul>
            </div>
        );


        if (type === 'mobile') {
            links = (
                <div className="d-block d-sm-none mobile-buttons-wrapper">
                    <div className="mobile-buttons">
                        <Link to={"/" + addEndingSlashToSlug(patientPage.slug)} className="btn btn-primary btn-forum btn-forum-mobile">{patientPage.title}</Link>
                        <Link to={forumPageSlug} className="btn btn-secondary btn-forum btn-forum-mobile">D.C. Research Forum</Link>
                    </div>
                </div>
            );
        }

        return links;
    }
}

export default TopLinks;