import React from "react";
import Title from '../page_parts/Title';
import Breadcrumb from '../page_parts/Breadcrumb';
import Axios from "axios";
import * as constants from '../../constants';
import {Link} from "react-router-dom";
import {addEndingSlashToSlug, getPageBySlug, levelNestedPages, removeStopwords} from "../../utils/general";
import CVRIPagination from "../page_parts/CVRIPagination";


class SearchPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            allPages: this.props.allPages,
            userSearchText: null,
            searchResults: [],
            total: 0,
        }

        this.pageLimit = 10;
        this.currentPgNo = 1;
    }

    render() {

        return (
            <>
                <Breadcrumb allPages={this.state.allPages} />
                <Title currentPage={{title: 'Search Results'}} />
                {this.__getSearchContent()}
            </>

        )

    }

    __loadPageContent = (getTotal, page=1) => {

        let userSearch = document.getElementById('cvri_search').value.trim();

        //if we are on the same page and search for the same thing again, avoid making api calls
        if (this.prevUserSearch && this.prevUserSearch === userSearch && page === this.prevSearchPage) {
            console.log('prev srch');
            return null;
        }

        const limit = this.pageLimit;

        const searchURI = constants.SEARCH_API_URL + `?__search=${removeStopwords(userSearch)}&limit=${limit}&page=${page}&get_total=${getTotal}`;
        Axios.get(encodeURI(searchURI))
        .then((result) => {

            let searchResult = result.data;
                let searchTotal = searchResult.total;
                let searchData = searchResult.data;

                let preparedSearchRresults = this.__prepareSearchResults(searchData);

                if (this._isMounted) {

                    let toState = {
                        userSearchText: userSearch,
                        searchResults: preparedSearchRresults,
                    }

                    if (getTotal) {
                        toState.total = searchTotal
                    }
                    //set value to avoid multiple searches.
                    this.prevUserSearch = userSearch;
                    this.prevSearchPage = page;
                    this.currentPgNo = page;

                    this.setState(toState);
                }
        })
        .catch((error) => {
            console.log('Error fetching the search results: ', error);
        });

    }

    /**
     * Display the search results depending on each type. 
     * @param {Array} data data that came from DB
     */
    __prepareSearchResults = (data) => {

        if (data.length ===0 ){
            return null;
        }

        const results = [];
        for (const srcItem of data) {
            let searchItemData = JSON.parse(srcItem.full_item);
            let srcDataType = srcItem.data_type;

            let srcResult = {
                title: '',
                content: '',
                slug: '',
            };
            switch (srcDataType) {
                case 'page':
                    srcResult.title = searchItemData.title;
                    srcResult.content = this.__extractExcerptContent(searchItemData.description);

                    //if page is part of the menu get full url
                    //there may possibly be a case where a page would be a sub page of another page but not in the menu, which is not covered here or anywhere. 
                    let menuNestedPage = getPageBySlug(searchItemData.slug, levelNestedPages( this.props.menuItems));
                    let srcPageSlug = '/' + searchItemData.slug;

                    if (menuNestedPage) {
                        srcPageSlug = addEndingSlashToSlug(menuNestedPage.full_slug);
                    }
                    srcResult.slug = srcPageSlug;

                break;
                case 'article': 
                    srcResult.title = searchItemData.title;
                    srcResult.content = this.__extractExcerptContent(searchItemData.description);
                    srcResult.slug = '/' + constants.NEWS_PARENT_SLUG + '/' + addEndingSlashToSlug(searchItemData.slug);
                break;
                case 'team_member':
                    srcResult.title = searchItemData.name;
                    srcResult.content = this.__extractExcerptContent(searchItemData.biography);
                    srcResult.slug = '/' + constants.TEAM_MEMBERS_PARENT_SLUG + '/' + addEndingSlashToSlug(searchItemData.slug);
                break;
                case 'event': 

                    if (!searchItemData.event_date) {
                        break;
                    }
                    
                    let eventType = constants.FORUM_FUTURE_EVENTS;
                    let eventDate = new Date(searchItemData.event_date);

                    if (eventDate < new Date()) {
                        eventType = constants.FORUM_PAST_EVENTS;
                    }

                    srcResult.title = searchItemData.title;
                    srcResult.content = '';
                    srcResult.slug = '/' + constants.CVRI_FORUM + '/' + eventType + '/?_id=' + searchItemData.id;
                break;
                default:
                    //empty
            }

            if (srcResult.title.length > 0) {
                results.push(srcResult);
            }
            
        }

        return results;
    }

    __extractExcerptContent = (s, size = 350) => {
        var span = document.createElement('span');
        span.innerHTML = s;
        let content = span.textContent || span.innerText;

        if (content.length > size) {
            content = content.substring(0,size) + "...";
        }

        return content;
    };

    handleSearchRequest = (event) => {
        if (event.type === 'keypress' && event.keyCode === 13) {
            this.__loadPageContent(true);
        } else if (event.type === 'click') {
            this.__loadPageContent(true);
        }
    }
    
    componentDidMount() {
        document.title = 'Search Results - CVRI';
        this._isMounted = true;
        this.__loadPageContent(true);
        document.getElementById('cvri_search_submit').addEventListener("click", this.handleSearchRequest);
        document.querySelector('.activate-search').addEventListener('keypress', this.handleSearchRequest);
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.getElementById('cvri_search_submit').removeEventListener('click', this.handleSearchRequest);
        document.querySelector('.activate-search').removeEventListener('keypress', this.handleSearchRequest);
    }


    /**
     * Returns the actual search content
     */
    __getSearchContent = () => {
        
    
        let searchResultContent = '';
        if (!this.state.userSearchText) {
            return null;
        }
        
        if (!this.state.searchResults || this.state.searchResults.length === 0) {
            searchResultContent = <h3>No result found for: "{this.state.userSearchText}"</h3>;
        } else {
            searchResultContent = [];
            for (const [index, srcRes] of this.state.searchResults.entries()) {
                searchResultContent.push(
                    (
                    <div className="results-info" key={index}>
                        <h3>
                            <Link to={srcRes.slug}>{srcRes.title}</Link>
                        </h3>
                        <p>
                            {srcRes.content}
                            <Link className="read-more" to={srcRes.slug}> Read more</Link>
                        </p>
                    </div>
                    )
                )
            }
        }

        return (
           <main id="main-content" className="inner-main spotlight-landing-layout template-no-gaps col-sm">
                <section id="spotlight-area" className="container">
                <div className="SearchResults">
                    <div id="ctl00_ctl00_p_lt_mainBody_main_MainWidget_SmartSearchDialogWithResults_srchResults_pnlSearchResults">
                        {searchResultContent}
                        <CVRIPagination currentPgNo={this.currentPgNo} total={this.state.total} type="search results" pageLimit={this.pageLimit} loadPageContent={this.__loadPageContent} activatePagingLinks={this.__activatePagingLinks} />
                    </div>
                </div>
            </section>
        </main>
        )
    }

    __activatePagingLinks = (event, currentPgNo) => {
            
        if (!event.target.classList.contains('disabled')){
            this.__loadPageContent(false, currentPgNo);
        }
        
        event.preventDefault();
    }

}




export default SearchPage;