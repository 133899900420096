let environment = process.env.NODE_ENV;

let cms_url = 'http://localhost:1697';

let currentBase = window.location.protocol + '//' + window.location.hostname;

if (window.location.hostname === 'mphdev.northeurope.cloudapp.azure.com') {
    cms_url = currentBase + '/api';
} else if (environment === 'production' ) {
    cms_url = currentBase + '/api';
}

export const BASE_API_URL = cms_url + '/';
export const NAVIGATION_API_URL = BASE_API_URL + 'main-menu';
export const PAGES_API_URL = BASE_API_URL + 'pages/';
export const PAGES_API_LIST = BASE_API_URL + 'pages/all';
export const FOOTER_API_URL = BASE_API_URL + 'footer';
export const ARTICLES_API_URL = BASE_API_URL + 'articles';
export const PUBLICATIONS_API_URL = BASE_API_URL + 'publications/get_by_tags';
export const PUBLICATIONS_TOP_TAGS_URL = BASE_API_URL + 'publications/get_top_tags';
export const TEAM_MEMBERS_API_URL = BASE_API_URL + 'teams';
export const CONTACT_API_URL = BASE_API_URL + 'contact';
export const SEARCH_API_URL = BASE_API_URL + 'cvrisearch';
export const EVENTS_API_URL = BASE_API_URL + 'events';
export let CMS_URL  = cms_url;
export const SUB_PAGES_KEY = 'sub_pages';
export const CDN_JWPLAYER_URL = 'https://cdn.jwplayer.com/libraries/gKWBLnb9.js';
export const JWP_PLAYLIST_URL = `https://cdn.jwplayer.com/v2/playlists/VT4hf3EV`;
export const JWP_PLAYLIST_SINGLE_VIDEOS_URL = `https://cdn.jwplayer.com/v2/playlists/VT4hf3EV`;
export const JWP_SINGLE_VIDEO_URL = 'https://cdn.jwplayer.com/v2/playlists/M4rPc1wf';
export const ASK_QUESTION_URL = BASE_API_URL + 'ask-question';


export var CURRENT_PATHNAME = window.location.pathname;